.percent {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

.complete {
  font-size: 12px;
  line-height: 14px;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip-preview {
  border-bottom: 1px dotted black;
}

.tooltip-text {
  background-color: white;
  border: 1px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  padding: 10px;
  text-align: center;
  visibility: hidden;
  width: 250px;
  white-space: normal;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
