.customToggle.react-toggle .react-toggle-track,
.customToggle.react-toggle .react-toggle-track:hover,
.customToggle.react-toggle--checked .react-toggle-track,
.customToggle.react-toggle--checked .react-toggle-track:hover {
  background-color: #f5f5fa !important;
  width: 32px;
  height: 18px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 40%);
}

.customToggle.react-toggle--checked .react-toggle-track,
.customToggle.react-toggle--checked .react-toggle-track:hover {
  background-color: #3c4fe0 !important;
  box-shadow: inset 0 1px 4px 0 rgb(35 38 59 / 10%);
}

.customToggle.react-toggle .react-toggle-thumb,
.customToggle.react-toggle .react-toggle-thumb:focus {
  background: linear-gradient(-180deg, #fff, #f5f5fa);
  border-radius: 9999px;
  box-shadow: 1px 1px 1px 0 rgb(35 38 59 / 5%);
  height: 16px;
  left: 1px;
  position: absolute;
  top: 1px;
  transition: all 100ms ease-in-out;
  width: 16px;
  border: 0;
}

.customToggle.react-toggle--checked .react-toggle-thumb {
  transform: translateX(14px);
}
